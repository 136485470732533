import {
  AccountingSettings,
  ApprovalConfirmationSettings,
  ArchiveSettings,
  AuthenticationSettings,
  CaseWorkersSettings,
  CustomValueSettings,
  DialogSettings, EmployeeNumberSettings, ExtraColumnsSettings,
  GdprSettings, ImportSettings, NotificationsSettings,
  OverviewXlsxSettings, PaymentReceiptsSettings,
  PaymentsSettings, ProfileSettings,
  RecommendationsSettings,
  SecuritySettings,
  Settings,
  SmsSettings,
  SupportSettings, TransferSettings, VotesSettings
} from './settings';
import {NestedReadonly} from "../types";

export const defaultTransferSettings: TransferSettings = {
  case_documents: false,
  messages: false,
  recommendation: false
}

export const defaultAccountingSettings: AccountingSettings = {
  payment_credit_account: '5678',
  payment_debit_account: '4321',
  provision_account_no: '4321',
  account_no: '1234'
}

export const defaultApprovalConfirmationSettings: ApprovalConfirmationSettings = {
  list_projects: false,
  automatic: {
    enabled: false,
    grace: 30
  }
}

export const defaultArchiveSettings: ArchiveSettings = {
  email: '',
  approvals: false,
  receipts: false,
  rejections: false
}

export const defaultAuthenticationSettings: AuthenticationSettings = {
  modes: ['mitid', 'nemid', 'codefile', 'tfa']
}


export const defaultCaseWorkersSettings: CaseWorkersSettings = {
  remove_delay: 5,
  daily_reminder: false
}

export const defaultCustomValueSettings: CustomValueSettings = {
  decimals: 1
}

export const defaultDialogSettings: DialogSettings = {
  shared_reader: false,
  internal_senders: [],
  thread_type: 'applicant'
}

export const defaultEmployeeNumberSettings: EmployeeNumberSettings = {
  employeeNoRegex: ''
}

export const defaultExtraColumnsSettings: ExtraColumnsSettings = {
  fields: []
}

export const defaultGdprSettings: GdprSettings = {
  newly_approved: 1,
  newly_refused: 1,
  refused_and_moved: 30,
  old_approved: 5,
  dangling: 15,
  total_history: true,
  inactive_profiles: 24,
  dead_drafts: 3,
  withdrawn: 10,
  attachment_keep_on_clean: false,
  message_keep_on_clean: false,
  purge_enabled: false,
  purge_dangling: 30,
  purge_refused: 30,
  purge_approved: 10,
  purge_log: 5,
  cleaner_disabled: false
}

export const defaultNotificationsSettings: NotificationsSettings = {
  cleanup: {
    noticed: 1,
    unnoticed: 30
  }
}

export const defaultOverviewXlsxSettings: OverviewXlsxSettings = {
  columns: [
    'project.journal_no',
    'applicant.company_or_name',
    'applicant.zip',
    'applicant.country',
    'project.approved_on',
    'overview.granted_amount',
    'overview.available_start',
    'overview.granted_during',
    'overview.paid_during',
    'overview.dropped_during',
    'overview.available_stop',
    'overview.hidden_stop'
  ]
};

export const defaultPaymentsSettings: PaymentsSettings = {
  notify_accounting: '',
  'file-format': 'PaymentsXlsx',
  notify_applicant: false,
  use_p_no: true,
  frequency: [],
  skip_weeks: []
}

export const defaultPaymentReceiptsSettings: PaymentReceiptsSettings = {
  receipts_amount_check: false
}

export const defaultProfileSettings: ProfileSettings = {
  account_alert_on_payment_request: false,
  logins: ['email'],
  signups: ['email'],
  force: true,
  confirmation: false,
  allow_copy_of_rejection: false
}

export const defaultRecommendationsSettings: RecommendationsSettings = {
  warning_multiple_applications: false,
  hide_priority: true,
  attachment_scale: 1.0,
  style: 'standard',
  compact_history: 'digest',
  show_private_address: false,
  show_private_name: false,
  priorities: ['1.0', '1.1', '1.2', '1.3', '1.4', '1.5', '1.6', '1.7', '2.0', '2.1', '2.2', '2.3', '2.4', '2.5', '2.6', '2.7']
}

export const defaultSecuritySettings: SecuritySettings = {
  skip_ip_check: false
}

export const defaultSmsSettings: SmsSettings = {
  all_messages: false,
  approval: false,
  refusal: false,
  sender: 'GrantOne'
}

export const defaultSiblingsSettings= []

export const defaultSupportSettings: SupportSettings = {
  email: 'support@grant.nu'
}

export const defaultVotesSettings: VotesSettings = {
  show_details: false
}

export const defaultImportSettings: ImportSettings = {
  show_import_project_statuses: false
}

export const defaultSettings: NestedReadonly<Settings> = {
  accounting: {
    ...defaultAccountingSettings,
  },
  approval_confirmation: {
    ...defaultApprovalConfirmationSettings,
  },
  archive: {
    ...defaultArchiveSettings,
  },
  authentication: {
    ...defaultAuthenticationSettings,
  },
  'case-workers': {
    ...defaultCaseWorkersSettings,
  },
  'custom-value': {
    ...defaultCustomValueSettings,
  },
  dialog: {
    ...defaultDialogSettings,
  },
  gdpr: {
    ...defaultGdprSettings,
  },
  overview_xlsx: {
    ...defaultOverviewXlsxSettings,
  },
  payments: {
    ...defaultPaymentsSettings,
  },
  'payment-receipts': {
    ...defaultPaymentReceiptsSettings,
  },
  recommendations: {
    ...defaultRecommendationsSettings
  },
  security: {
    ...defaultSecuritySettings,
  },
  sms: {
    ...defaultSmsSettings,
  },
  support: {
    ...defaultSupportSettings,
  },
  'automation': {},
  'bulk_actions': {},
  'paper-refusal': {
    template: ''
  },
  'board': {
    version: 'v4',
    language: 'da'
  },
  'related-applications': {},
  'scoring': {},
  'paper-letters': {
    'letter-types': []
  },
  'comments': {
    shared: false
  },
  notifications: {
    ...defaultNotificationsSettings
  },
  profile: {
    ...defaultProfileSettings,
  },
  'forecast': {
    no_exceeding_available: false,
    least_one_source: false,
    multiple_sources: false,
    allow_change_after_approval: false,
    dimensions: []
  },
  'languages': ['da'],
  'siblings': [],
  transfer: {
    ...defaultTransferSettings
  },
  'criteria': {
    individual: false,
    pct_precision: 1
  },
  materialized_view: {
    schedule: 3
  },
  import: {
    ...defaultImportSettings
  },
  applicant: {
    digest: '',
    force_digest: false
  },
  tag: '',
  votes: {
    ...defaultVotesSettings
  },
  employee_no: {
    ...defaultEmployeeNumberSettings
  }
};
