export * from './approval-confirmation-settings-dialog/approval-confirmation-settings-dialog.component';
export * from './archive-settings-dialog/archive-settings-dialog.component';
export * from './automation-settings-dialog/automation-settings-dialog.component';
export * from './comments-settings-dialog/comments-settings-dialog.component';
export * from './criteria-settings-dialog/criteria-settings-dialog.component';
export * from './forecast-settings-dialog/forecast-settings-dialog.component';
export * from './payment-receipts-settings-dialog/payment-receipts-settings-dialog.component';
export * from './profile-settings-dialog/profile-settings-dialog.component';
export * from './recommendations-settings-dialog/recommendations-settings-dialog.component';
export * from './refusal-settings-dialog/refusal-settings-dialog.component';
export * from './scoring-settings-dialog/scoring-settings-dialog.component';
export * from './portal-settings-dialog/portal-settings-dialog.component';
