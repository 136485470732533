<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>Betalinger</mat-panel-title>
  </mat-expansion-panel-header>
  <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1em" class="mb-3" *ngIf="form.disabled">
    <dl fxFlex="45" class="settings-list">
      <dt>Betalingsfil</dt>
      <dd [ngSwitch]="value['file-format']">
        <span *ngSwitchCase="'PaymentsXlsx'">Generisk (Excel)</span>
        <span *ngSwitchCase="'NordeaCsv'">Nordea (CSV)</span>
        <span *ngSwitchCase="'BusinessOnlineCsv'">Danske Bank (CSV)</span>
        <span *ngSwitchCase="'NykreditCsv'">Nykredits Bank (CSV)</span>
        <span *ngSwitchCase="'SydbankCsv'">Sydbank (CSV)</span>
      </dd>

      <dt *ngIf="showUsePNo()">Tillad P-nummer i virksomheds NemKonto</dt>
      <dd *ngIf="showUsePNo()">{{value.use_p_no ? 'Ja' : 'Nej'}}</dd>

      <dt>Betalingsfrekvens</dt>
      <dd>
        <span *ngIf="!value.frequency?.length">Alle hverdage</span>
        <span *ngIf="value.frequency?.length">{{ value.frequency.join(', ')}}</span>
      </dd>
    </dl>
    <mat-divider [vertical]="true"></mat-divider>
    <dl fxFlex="45" class="settings-list">
      <dt>Økonomifunktions email</dt>
      <dd>
        <span *ngIf="!value.notify_accounting"> -- </span>
        <span *ngIf="value.notify_accounting">{{ value.notify_accounting }}</span>
      </dd>

      <dt>Besked til ansøger ved udbetaling</dt>
      <dd>{{ value.notify_applicant ? 'Ja' : 'Nej' }}</dd>
    </dl>
  </div>
  <form [formGroup]="form" *ngIf="form.enabled">
    <div fxLayout="row" fxLayoutAlign="space-between" class="mb-3">
      <div fxFlex="45" fxLayout="column">
        <label>Betalingsfil</label>
        <mat-form-field>
          <mat-select formControlName="file-format">
            <mat-option value="PaymentsXlsx">Generisk (Excel)</mat-option>
            <mat-option value="NordeaCsv">Nordea</mat-option>
            <mat-option value="BusinessOnlineCsv">Danske Bank</mat-option>
            <mat-option value="NykreditCsv">Nykredits Bank</mat-option>
            <mat-option value="SydbankCsv">SydBank</mat-option>
          </mat-select>
          <mat-hint>Vælg format, som svarer til fondens netbank. Kun relevant for manuel overførsel.</mat-hint>
          <mat-error *ngIf="form.controls['file-format'].hasError('required')">Obligatorisk.</mat-error>
        </mat-form-field>
      </div>
      <div fxFlex="45" fxLayout="column">
        <label>Økonomifunktions email</label>
        <mat-form-field>
          <input type="email" matInput formControlName="notify_accounting">
          <mat-hint>Hvis angivet sendes betalingsbilag til email ved ekspedition.</mat-hint>
          <mat-error *ngIf="form.controls['notify_accounting'].hasError('email')">Ugyldig email.</mat-error>
          <mat-error *ngIf="form.controls['notify_accounting'].hasError('maxlength')">Højst 100 tegn.</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between" class="mb-3">
      <div fxFlex="45">
        <mat-checkbox *ngIf="showUsePNo()" formControlName="use_p_no">Tillad P-nummer i virksomheds NemKonto.</mat-checkbox>
      </div>
      <mat-checkbox fxFlex="45" formControlName="notify_applicant">Send besked til ansøger ved ekspedition af udbetaling</mat-checkbox>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between" class="mb-3">
      <div fxFlex="45" fxLayout="column">
        <label>Betalingsfrekvens</label>
        <mat-form-field>
          <mat-select formControlName="frequency" multiple>
            <mat-option [value]="1">Mandag</mat-option>
            <mat-option [value]="2">Tirsdag</mat-option>
            <mat-option [value]="3">Onsdag</mat-option>
            <mat-option [value]="4">Torsdag</mat-option>
            <mat-option [value]="5">Fredag</mat-option>
          </mat-select>
          <mat-hint>Angiv hvor ofte automatisk betalingsfiler skal overføres. Intet valg svarer til dagligt.</mat-hint>
        </mat-form-field>
      </div>
    </div>
  </form>
  <mat-action-row>
    <button *ngIf="form.disabled" mat-raised-button color="accent" (click)="doEdit()">Rediger</button>
    <div *ngIf="form.enabled">
      <button mat-raised-button color="primary" (click)="doCancel()">Fortryd</button>
      <button mat-raised-button color="accent" (click)="doSave()" [disabled]="form.invalid">Gem</button>
    </div>
  </mat-action-row>
</mat-expansion-panel>
